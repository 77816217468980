import React, { Component } from "react";
import "./Settings.scss";
import axios from "axios";

class Settings extends Component {
  state = {
    openMovie: false,
    openSeries: false,
    openLanguages: false,
    languagedata: "",
    movie: "",
    series: "",
    languagename: "",
    language: [],
  };

  componentDidMount() {
    let movie_name = localStorage.getItem('server_movie');
    let series_name = localStorage.getItem('server_series');
    this.setState({ languagename: localStorage.getItem('language_eng') });

    // Movie servers
    if (movie_name == 1) {
      this.setState({ movie: "AUTO EMBED" });
    } else if (movie_name == 2) {
      this.setState({ movie: "Hindi Server" });
    } else if (movie_name == 3) {
      this.setState({ movie: "MULTI EMBED" });
    } else if (movie_name == 4) {
      this.setState({ movie: "Vidbinge" });
    } else if (movie_name == 5) {
        this.setState({ movie: "Vidlink" });
    } else if (movie_name == 6) {
        this.setState({ movie: "Vidsrc(cc)" });
    } else if (movie_name == 7) {
        this.setState({ movie: "Smashystream" });
    } else if (movie_name == 8) {
        this.setState({ movie: "Vidsrc(pro)" });
    } else if (movie_name == 9) {
        this.setState({ movie: "FRENCH SERVER" });
    } else if (movie_name == 10) {
        this.setState({ movie: "VIETNAM SERVER" });
    } else if (movie_name == 11) {
      this.setState({ movie: "VidSrc.xyz" });
    }

    // Series servers
    if (series_name == 1) {
      this.setState({ series: "AUTO EMBED" });
    } else if (series_name == 2) {
      this.setState({ series: "Hindi Server" });
    } else if (series_name == 3) {
      this.setState({ series: "MULTI EMBED" });
    } else if (series_name == 4) {
        this.setState({ series: "Vidbinge" });
    } else if (series_name == 5) {
        this.setState({ series: "Vidlink" });
    } else if (series_name == 6) {
        this.setState({ series: "Vidsrc(cc)" });
    } else if (series_name == 7) {
        this.setState({ series: "Smashystream" });
    } else if (series_name == 8) {
        this.setState({ series: "Vidsrc(pro)" });
    } else if (series_name == 9) {
        this.setState({ series: "FRENCH SERVER" });
    } else if (series_name == 10) {
        this.setState({ series: "VIETNAM SERVER" });
    } else if (series_name == 11) {
        this.setState({ series: "VidSrc.xyz" });
    }

    axios.get(`https://api.themoviedb.org/3/configuration/languages?api_key=453752deba3272cd109112cd41127fd8`)
      .then((response) => {
        this.setState({
          language: [
            { "iso_639_1": "en", "english_name": "English", "name": "English" },
            { "iso_639_1": "hi", "english_name": "Hindi", "name": "हिन्दी" },
            { "iso_639_1": "de", "english_name": "German", "name": "Deutsch" },
            { "iso_639_1": "fr", "english_name": "French", "name": "Français" },
            { "iso_639_1": "es", "english_name": "Spanish", "name": "Español" },
            { "iso_639_1": "vi", "english_name": "Vietnamese", "name": "Tiếng Việt" },
            { "iso_639_1": "ko", "english_name": "Korean", "name": "한국어/조선말" },
        
                { "iso_639_1": "ru", "english_name": "Russian", "name": "Русский" },
                { "iso_639_1": "ar", "english_name": "Arabic", "name": "العربية" },
                { "iso_639_1": "ja", "english_name": "Japanese", "name": "日本語" },
                { "iso_639_1": "pt", "english_name": "Portuguese", "name": "Português" },
                { "iso_639_1": "it", "english_name": "Italian", "name": "Italiano" },
                { "iso_639_1": "tr", "english_name": "Turkish", "name": "Türkçe" },
                { "iso_639_1": "th", "english_name": "Thai", "name": "ไทย" },
                { "iso_639_1": "ms", "english_name": "Malay", "name": "Melayu" },
                { "iso_639_1": "sv", "english_name": "Swedish", "name": "Svenska" },
                { "iso_639_1": "da", "english_name": "Danish", "name": "Dansk" },
                { "iso_639_1": "ta", "english_name": "Tamil", "name": "தமிழ்" },
            { "iso_639_1": "te", "english_name": "Telugu", "name": "తెలుగు" },
            { "iso_639_1": "pa", "english_name": "Punjabi", "name": "ਪੰਜਾਬੀ" },
            { "iso_639_1": "bn", "english_name": "Bengali", "name": "বাংলা" },
          ],
        })
      }).catch(err => console.log(err));
  }

  // Toggle movie dropdown
  toggleServers = () => {
    this.setState(prevState => ({
      openMovie: !prevState.openMovie,
      openSeries: false,
      openLanguages: false
    }));
  };

  // Toggle series dropdown
  toggleSeries = () => {
    this.setState(prevState => ({
      openSeries: !prevState.openSeries,
      openMovie: false,
      openLanguages: false
    }));
  };

  // Toggle languages dropdown
  toggleLanguages = () => {
    this.setState(prevState => ({
      openLanguages: !prevState.openLanguages,
      openMovie: false,
      openSeries: false
    }));
  };

  // Movie server selection
  chooseServer = (serverName, serverId) => {
    this.setState({ movie: serverName, openMovie: false });
    localStorage.setItem('server_movie', serverId);
  };

  // Series server selection
  chooseSeriesServer = (serverName, serverId) => {
    this.setState({ series: serverName, openSeries: false });
    localStorage.setItem('server_series', serverId);
  };

  // Language selection
  handleClick = (e, langIso, langName) => {
    localStorage.setItem('language', langIso);
    localStorage.setItem('language_eng', langName);
    this.setState({ languagename: langName, openLanguages: false });
    window.location.reload(false);
  };

  render() {
    return (
      <div className="container settings">
        {/* Language Source Dropdown */}
        <div className="sc-1o36vqg-7 brWjXb">
          <header className="sc-1o36vqg-4 kYxrQw">
            <div className="sc-1o36vqg-3 iVeijx">
              <div className="sc-1o36vqg-0 hfwCMq">
                Change the default language source. (Need to reload to take effect)
              </div>
            </div>
          </header>
          <hr className="sc-120wsjt-0 sc-1o36vqg-2 keqMWH" />
          <div className="sc-1o36vqg-6 hwytcN">
            <div className="zpicwb-12 erjEHL">
              <div className="zpicwb-10 gmjUwa">
                <div className="zpicwb-11 jBNrZF" onClick={this.toggleLanguages}>
                  <div className="zpicwb-3 bjxAPK">
                    {this.state.languagename ? this.state.languagename : "Select Language"}
                  </div>
                </div>
                <div className="zpicwb-9 cHJOkB">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="m9d8o3-0 fxbeUc zpicwb-8 eKqRtN">
                    <path d="M17 9.5l-5 5-5-5" stroke="#9B9D9F" data-stroke="main" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                  <ul className="sc-12gjfzm-1 hqidmZ zpicwb-0 jBOakZ" style={{ display: this.state.openLanguages ? "block" : "none" }}>
                    {this.state.language && this.state.language.map((lang, i) => (
                      <li
                        className="sc-12gjfzm-0 PMXqu"
                        onClick={(e) => this.handleClick(e, lang.iso_639_1, lang.english_name)}
                        key={i}
                      >
                        {lang.english_name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Movie Source Dropdown */}
        <div className="sc-1o36vqg-7 brWjXb" style={{ position: 'relative' }}>
          <header className="sc-1o36vqg-4 kYxrQw">
            <div className="sc-1o36vqg-3 iVeijx">
              <div className="sc-1o36vqg-0 hfwCMq">Change the default Movie Source.</div>
            </div>
          </header>
          <hr className="sc-120wsjt-0 sc-1o36vqg-2 keqMWH" />
          <div className="sc-1o36vqg-6 hwytcN">
            <div className="zpicwb-12 erjEHL">
              <div className="zpicwb-10 gmjUwa">
                <div className="zpicwb-11 jBNrZF" onClick={this.toggleServers}>
                  <div className="zpicwb-3 bjxAPK">{this.state.movie ? this.state.movie : "Select Server"}</div>
                </div>
                <div className="zpicwb-9 cHJOkB">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="m9d8o3-0 fxbeUc zpicwb-8 eKqRtN">
                    <path d="M17 9.5l-5 5-5-5" stroke="#9B9D9F" data-stroke="main" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                  <ul className="sc-12gjfzm-1 hqidmZ zpicwb-0 jBOakZ" style={{
                                display: this.state.openMovie ? "block" : "none",
                                position: "absolute",
                                top: "100%", // Position it directly below the button
                                left: "0",
                                maxHeight: "170px",
                                overflowY: "auto",
                                scrollbarWidth: "thin",
                                scrollbarColor: "#d8d8d8 transparent" // Updated scrollbar color
                            }}>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseServer("AUTO EMBED", 1)}>AUTO EMBED</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseServer("Hindi Server", 2)}>Hindi Server</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseServer("MULTI EMBED", 3)}>MULTI EMBED</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseServer("Vidbinge", 4)}>Vidbinge</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseServer("Vidlink", 5)}>Vidlink</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseServer("Vidsrc(cc)", 6)}>Vidsrc(cc)</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseServer("Smashystream", 7)}>Smashystream</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseServer("Vidsrc(pro)", 8)}>Vidsrc(pro)</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseServer("FRENCH SERVER", 9)}>FRENCH SERVER</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseServer("VIETNAM SERVER", 10)}>VIETNAM SERVER</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseServer("VidSrc.xyz", 11)}>VidSrc.xyz</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Series Source Dropdown */}
        <div className="sc-1o36vqg-7 brWjXb" style={{ position: 'relative' }}>
          <header className="sc-1o36vqg-4 kYxrQw">
            <div className="sc-1o36vqg-3 iVeijx">
              <div className="sc-1o36vqg-0 hfwCMq">Change the default Series Source.</div>
            </div>
          </header>
          <hr className="sc-120wsjt-0 sc-1o36vqg-2 keqMWH" />
          <div className="sc-1o36vqg-6 hwytcN">
            <div className="zpicwb-12 erjEHL">
              <div className="zpicwb-10 gmjUwa">
                <div className="zpicwb-11 jBNrZF" onClick={this.toggleSeries}>
                  <div className="zpicwb-3 bjxAPK">{this.state.series ? this.state.series : "Select Server"}</div>
                </div>
                <div className="zpicwb-9 cHJOkB">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="m9d8o3-0 fxbeUc zpicwb-8 eKqRtN">
                    <path d="M17 9.5l-5 5-5-5" stroke="#9B9D9F" data-stroke="main" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                  <ul className="sc-12gjfzm-1 hqidmZ zpicwb-0 jBOakZ" style={{
                                display: this.state.openSeries ? "block" : "none",
                                position: "absolute",
                                top: "100%", // Position it directly below the button
                                left: "0",
                                maxHeight: "170px",
                                overflowY: "auto",
                                scrollbarWidth: "thin",
                                scrollbarColor: "#d8d8d8 transparent" // Updated scrollbar color
                            }}>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseSeriesServer("AUTO EMBED", 1)}>AUTO EMBED</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseSeriesServer("Hindi Server", 2)}>Hindi Server</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseSeriesServer("MULTI EMBED", 3)}>MULTI EMBED</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseSeriesServer("Vidbinge", 4)}>Vidbinge</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseSeriesServer("Vidlink", 5)}>Vidlink</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseSeriesServer("Vidsrc(cc)", 6)}>Vidsrc(cc)</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseSeriesServer("Smashystream", 7)}>Smashystream</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseSeriesServer("Vidsrc(pro)", 8)}>Vidsrc(pro)</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseSeriesServer("FRENCH SERVER", 9)}>FRENCH SERVER</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseSeriesServer("VIETNAM SERVER", 10)}>VIETNAM SERVER</li>
                    <li className="sc-12gjfzm-0 PMXqu" onClick={() => this.chooseSeriesServer("VidSrc.xyz", 11)}>VidSrc.xyz</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
