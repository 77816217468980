import React, { useState } from "react";
import SidebarNavLink from "./SidebarNavLink/SidebarNavLink";
import { Mobile } from "../Responsive";
import { SignedIn, SignedOut } from "../UserState/UserState";
import { withRouter } from "react-router-dom";  // Importando withRouter para redirecionamento
import { signOut } from "../../Firebase/UserUtils";  // Importando signOut
import "./BottomNav.scss";

// list of icons
import SwitchIcon from "../../assets/switch.svg";
import HomeIcon from "../../assets/home.svg";
import MoviesIcon from "../../assets/movie.svg";
import TvIcon from "../../assets/tv.svg";
import SettingsIcon from "../../assets/settings.svg";
import HelpIcon from "../../assets/help.svg";
import DiscordIcon from "../../assets/discord.svg";
import HideIcon from "../../assets/hide.svg";
import SignalIcon from "../../assets/signal.svg";
import WatchingIcon from "../../assets/watching.svg";
import PlannedIcon from "../../assets/planned.svg";
import CompletedIcon from "../../assets/completed.svg";
import DroppedIcon from "../../assets/dropped.svg";
import UserIcon from "../../assets/user.svg";

function BottomNav({ history }) {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleLogout = () => {
    signOut().then(() => {
      history.push('/login');
      setShowMore(false);  // Fechar o menu adicional após o logout
    });
  };

  const handleItemClick = () => {
    setShowMore(false);
  };

  return (
    <Mobile>
      <div className="bottom-nav">
        <SidebarNavLink exact to="/" activeClassName="active">
          <SignalIcon fill="currentColor" />
          <span className="nav-text">Explore</span>
        </SidebarNavLink>
        <SidebarNavLink to="/movies" activeClassName="active">
          <MoviesIcon fill="currentColor" />
          <span className="nav-text">Movies</span>
        </SidebarNavLink>
        <SidebarNavLink to="/shows" activeClassName="active">
          <TvIcon fill="currentColor" />
          <span className="nav-text">TV Shows</span>
        </SidebarNavLink>
        <SidebarNavLink to="/settings" activeClassName="active">
          <SettingsIcon fill="currentColor" />
          <span className="nav-text">Settings</span>
        </SidebarNavLink>
        <SidebarNavLink to="/help" activeClassName="active">
          <HelpIcon fill="currentColor" />
          <span className="nav-text">FAQs</span>
        </SidebarNavLink>
        
        <div className="more-menu">
          <button className="more-button" onClick={toggleShowMore}>
            <SwitchIcon fill="currentColor" />
            <span className="nav-text">More</span>
          </button>
          <div className={`additional-menu ${showMore ? "show" : ""}`}>
            <SignedIn>
              {user => (
                <>
                  <SidebarNavLink to={`/user/${user.uid}/watching/`} onClick={handleItemClick}>
                    <WatchingIcon fill="currentColor" />
                    <span className="nav-text">Watching</span>
                  </SidebarNavLink>
                  <SidebarNavLink to={`/user/${user.uid}/plan_to_watch/`} onClick={handleItemClick}>
                    <PlannedIcon fill="currentColor" />
                    <span className="nav-text">Planned</span>
                  </SidebarNavLink>
                  <SidebarNavLink to={`/user/${user.uid}/completed/`} onClick={handleItemClick}>
                    <CompletedIcon fill="currentColor" />
                    <span className="nav-text">Completed</span>
                  </SidebarNavLink>
                  <SidebarNavLink to={`/user/${user.uid}/dropped/`} onClick={handleItemClick}>
                    <DroppedIcon fill="currentColor" />
                    <span className="nav-text">Abandoned</span>
                  </SidebarNavLink>
                  <button className="more-button" onClick={handleLogout}>
                    <UserIcon fill="currentColor" />
                    <span className="nav-text">Logout</span>
                  </button>
                </>
              )}
            </SignedIn>
            <SignedOut>
              <>
                <SidebarNavLink to="/login" onClick={handleItemClick}>
                  <UserIcon fill="currentColor" />
                  <span className="nav-text">Login</span>
                </SidebarNavLink>
                <div className="notif">Log in to view your Watchlist</div>
              </>
            </SignedOut>
          </div>
        </div>
      </div>
    </Mobile>
  );
}

export default withRouter(BottomNav);